module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<table id="key">\n	<!-- <tr>\n		<td>\n			'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' Here you are!</td>\n		<td rowspan=10>\n			<img id=\'logo\' src="' + require("/opt/telegauge/node_modules/underscore-template-loader/file-loader.js?url=..%2F..%2Fimg%2Flogo%20with%20text%20tall.png!../../img/logo with text tall.png") + '">\n		</td>\n	</tr> -->\n	<tr>\n		<td>\n			'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' A few common site-wide <a href=\'#DT/help/settings\'>settings</a>.</td>\n	</tr>\n	<tr>\n		<td>\n			'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' Manage data-collection <a href=\'#DT/help/pollers\'>pollers</a>.</td>\n	</tr>\n	<tr>\n		<td>\n			'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' Manage physical <a href=\'#DT/help/devices\'>devices</a>, and the <a href=\'#DT/help/tags\'>tags</a> they provide.</td>\n	</tr>\n	<tr>\n		<td>\n			'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' Set up <a href=\'#DT/help/users\'>user</a> accounts, and <a href=\'#DT/help/permissions\'>permissions</a>.</td>\n	</tr>\n	<tr>\n		<td>\n			'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' Create <a href=\'#DT/help/screens\'>screens</a>. Use "edit" to add <a href=\'#DT/help/widgets\'>widgets</a>.</td>\n	</tr>\n	<tr>\n		<td>\n			'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' Create and manage <a href=\'#DT/help/reports\'>reports</a>.</td>\n	</tr>\n	<tr>\n		<td>\n			'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' A repository of online <a href=\'#DT/help/files\'>files</a>, such as user manuals.</td>\n	</tr>\n	<tr>\n		<td>\n			'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' A live view of the <a href=\'#DT/help/network\'>network</a> that Telegauge devices are a part of.</td>\n	</tr>\n	<tr>\n		<td>\n			'+
((__t=(APP.Tools.icon("arrow-left")))==null?'':__t)+
' A raw view into the Telegauge <a href=\'#DT/help/database\'>database</a>. Here be dragons.</td>\n	</tr>\n</table>\n'+
((__t=(SETTINGS.name))==null?'':__t)+
'\n	<h1>Telegauge Online Help</h1>\n	<center>\n		<b>'+
((__t=(version()))==null?'':__t)+
'</b>\n	</center>\n\n	<h2>I need help!</h2>\n	<ul>\n		<li>Send us an email:\n			<a href=\'mailto:telegauge@gmail.com?subject=['+
((__t=(SETTINGS.name))==null?'':__t)+
'] I need help\'>\n				'+
((__t=(APP.Tools.icon('envelope')))==null?'':__t)+
' telegauge@gmail.com</a>\n		</li>\n		<!-- <li>Join us on Slack:\n			<a href=\'https://join.slack.com/t/telegauge/signup\'>\n				'+
((__t=(APP.Tools.icon('slack')))==null?'':__t)+
' Join</a>\n		</li> -->\n	</ul>\n\n	<h3>Table of Contents</h3>\n	<div id=\'toc\'>\n  <div class=\'group\'>\n    <li><a href=\'#DT/help/gettingstarted\'>Gettings Started</a></li>\n    <li><a href=\'#DT/help/setuppollers\'>Setting Up Pollers</a></li>\n    <li><a href=\'#DT/help/managedevice\'>Managing Devices</a></li>\n    <li><a href=\'#DT/help/editingscreens\'>Editing screens</a></li>\n    <li><a href=\'#DT/help/monitornetwork\'>Monitoring the network</a></li>\n		<li><a href=\'#DT/help/usedatabase\'>Using the Database</a></li>\n		<li><a href=\'#DT/help/revisions\'>Telegauge Revision History</a></li>\n  </div>\n\n	<div class=\'group\'>\n  <li><a href=\'#DT/help/settings\'>'+
((__t=(APP.Tools.icon('settings')))==null?'':__t)+
' Settings</a></li>\n  <li><a href=\'#DT/help/pollers\'>'+
((__t=(APP.Tools.icon('pollers')))==null?'':__t)+
' Pollers</a></li>\n  <li><a href=\'#DT/help/device_library\'>'+
((__t=(APP.Tools.icon('device_library')))==null?'':__t)+
' Device Library</a></li>\n	<li><a href=\'#DT/help/devices\'>'+
((__t=(APP.Tools.icon('devices')))==null?'':__t)+
' Devices</a></li>\n	<li><a href=\'#DT/help/network\'>'+
((__t=(APP.Tools.icon('network')))==null?'':__t)+
' Network</a></li>\n  <li><a href=\'#DT/help/tag_library\'>'+
((__t=(APP.Tools.icon('tag_library')))==null?'':__t)+
' Tag Library</a></li>\n  <li><a href=\'#DT/help/tags\'>'+
((__t=(APP.Tools.icon('tags')))==null?'':__t)+
' Tags</a></li>\n  <li><a href=\'#DT/help/widgets\'>'+
((__t=(APP.Tools.icon('widgets')))==null?'':__t)+
' Widgets</a></li>\n  <li><a href=\'#DT/help/users\'>'+
((__t=(APP.Tools.icon('users')))==null?'':__t)+
' Users</a></li>\n  </div>\n	</div>\n\n	<p>Telegauge collects data from many different sources via <a href=\'#DT/help/pollers\'>Pollers</a>, stores the data in the <a href=\'#DT/help/database\'>Database</a>, displays data on <a href=\'#DT/help/screens\'>Screens</a>, using <a href=\'#DT/help/widgets\'>Widgets</a>.\n	</p>\n\n	<p>In order to collect data, Devices must be created. Each device has a protocol, and each protocol is attached to one or more Pollers. As pollers collect data for a device, each piece of data is stored in the device\'s tag. There is a live status of pollers on the left sidebar.</p>\n\n	<p>Pollers generally run on one or more Telegauge hardware devices, and use a cloud-based server to provide the screens. The status of each machine used in the Telegauge ecosystem is also displayed on the left sidebar.</p>\n\n	<h3>Topology</h3>\n	<div id=\'topology\'>\n  <div class="tree">\n  <ul>\n    <li>\n      <a href="#">'+
((__t=(APP.Tools.icon("data")))==null?'':__t)+
' data</a>\n\n  	<ul>\n    <li>\n      <a href="#">'+
((__t=(APP.Tools.icon("tags")))==null?'':__t)+
' Tag</a>\n      <ul>\n        <li>\n          <a href="#">'+
((__t=(APP.Tools.icon("tag_library")))==null?'':__t)+
' Tag Library</a>\n        </li><li>\n          <a href="#">'+
((__t=(APP.Tools.icon("devices")))==null?'':__t)+
' Device</a>\n          <ul>\n            <li>\n              <a href="#">'+
((__t=(APP.Tools.icon("device_library")))==null?'':__t)+
' Device Library</a>\n            </li><li>\n              <a href="#">'+
((__t=(APP.Tools.icon("pollers")))==null?'':__t)+
' Poller</a>\n              <ul>\n                <li>\n                  <a href=\'#\'>'+
((__t=(APP.Tools.icon("protocol")))==null?'':__t)+
' Protocol</a>\n                </li>\n              </ul>\n            </li>\n          </ul>\n        </li>\n      </ul>\n    </li>\n  </ul>\n</li>\n\n</ul>\n</div>\n	<i><b>Fig 1:</b> Relation between different data types</i>\n	</div>\n\n	<p>Each piece of data collected by Telegauge is stored in a\n		<a href="#DT/help/tags">\n			'+
((__t=(APP.Tools.icon("tags")))==null?'':__t)+
' Tag</a>. The tag stores the current value, as well as minimum and maximum values, and other statistical information about the value. A tag is a combination of a Device and a Tag Library.</p>\n	<p>To create a tag, Telegauge combines connection information from a Device with definition data from the Tag Library. The Tag Library contains things like the tag\'s name, it\'s ranges, and most importantly, it\'s <b>symbol</b>. A symbol is a short, all-caps name of the tag. For example, a tag that collects kilowatt data might choose "KW" for the symbol. The device, which has a prefix (such as G1), is combined with the tag library to create the actual tag, G1_KW in this example.</p>\n\n	<p>As pollers run, they use polling rates and addresses from the tag\'s Library, and connection information from the tag\'s Device. When new data is found, the Tag is updated with the new value, and any widget that displays it is also updated. If the tag has been told to store data (for Trending, for example), it is also written to a Data table, and stored forever.</p>\n\n	<h3>Data Collection</h3>\n\n	<div id=\'topology\'>\n  <div class="tree">\n\n  	<ul>\n    <li>\n      <a href="#">'+
((__t=(APP.Tools.icon('database')))==null?'':__t)+
' Telegauge</a>\n      <ul>\n        <li>\n          <a href="#">Cloud Server</a>\n          <ul>\n            <li>\n              <a href="#">'+
((__t=(APP.Tools.icon("pollers")))==null?'':__t)+
' Wunderground</a>\n            </li><li>\n              <a href="#">'+
((__t=(APP.Tools.icon("pollers")))==null?'':__t)+
' Fitbit</a>\n            </li>\n            <li>\n              <a href=\'#DT/help/machinepoller\'>'+
((__t=(APP.Tools.icon("pollers")))==null?'':__t)+
' Machine</a>\n            </li>\n          </ul>\n        </li>\n        <li>\n          <a href="#"><img src=\'' + require("/opt/telegauge/node_modules/underscore-template-loader/file-loader.js?url=..%2F..%2Fimg%2Fcl100.png!../../img/cl100.png") + '\'> Brick</a>\n          <ul>\n            <li>\n              <a href="#">'+
((__t=(APP.Tools.icon("pollers")))==null?'':__t)+
' Modbus</a>\n            </li><li>\n              <a href=\'#DT/help/machinepoller\'>'+
((__t=(APP.Tools.icon("pollers")))==null?'':__t)+
' Machine</a>\n            </li>\n          </ul>\n        </li>\n        <li>\n          <a href="#"><img src=\'' + require("/opt/telegauge/node_modules/underscore-template-loader/file-loader.js?url=..%2F..%2Fimg%2Fml400.png!../../img/ml400.png") + '\'> Brick</a>\n        </li>\n      </ul>\n    </li>\n\n</ul>\n</div>\n	<i><b>Fig 2:</b> Data collection configuration (example)</i>\n	</div>\n';
}
return __p;
};
