/**
 * Copy this file to settings.js and re-deploy
 *
 * Pay attention to http https in the urls
 * Also gotta re-deploy when this is changed.
 */

const settings = {
	dburl: 'https://juicibeef.telegauge.com/telegauge', // location of mongo server
	posturl: 'https://juicibeef.telegauge.com', // base url that handles all POST
	name: 'JuiciBeef', // intallation name
	dburi: '127.0.0.1', // points to the server where the database lives
	dbname: 'telegauge', // also might not be needed
	poller_suffix: false, // if multiple Telegauges are running, you need a suffix
	db_port: false, // alternate port, other than 3500
	basename: false, // alternate base dir, other than /opt/telegauge
	enable_cron: false, // use PM2's cron stuff for things like sending reports
	pollers: [ // contains a list of pollers that PM2 should start`
		'DB',
		'machine'
	],
	logo_url: 'assets/apple-touch-icon-1024x1024.png',
	emails       : {
		bugreport   : 'genplant_rsl@issues.zohoprojects.com',
		alarm_ticket: 'genplant_rsl@pop.zohoprojects.com'
	},
	reportcss_url: '/css/report.css',
	shortname: 'JBL',
	poller_args: '-rD', // args passed to pollers when starting up.
	poller_max_mem: '185M', // Max memory before poller auto-restart
	poller_restart_delay: 60000, // time between poller crash and restart
	//* * Enable this for SMS messaging.
	twilio: {
		sid: 'AC731f9bf0c7acaadd70030f234d2955f7',
		token: '308a4605c92f17c96478969b4d1c41e4',
		phone: '+19044252778'
	}
};

module.exports = settings;
